.cont_app {
    display: flex;
    flex-direction: column;
    align-items:center;
    align-items: flex-center;
    /*border: 1px solid;*/
}

.cont_general {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    border: 1px solid;
    width: 900px;
}

.container1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid;
}

.cont_Datos {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid;
}

.cont_inputs {
    display: flex;
    flex-direction: row;
    /*border: 1px solid;*/
    width: 400px;
}

.textbox {
    /* border-color: rgb(240, 114, 11); */
    width: 80px;
    /*float: left;*/
}

.label {
    width: 200px;
}

.wrap {
    margin: auto;
}

.comp_input {
    width: 500px;
    border: 1px solid rgba(67, 152, 201, 0.918);
    }

.input_title {
    float: left;
}

.input_data {
    float: right;
    border: 1px solid rgba(67, 152, 201, 0.918);
}

/* div {
    border: 1px solid black;
    } */